@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200;300;400;500;600;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Baloo 2';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.subtitle{
  font-size: 19px;
  line-height: 35px;
  font-family: 'Mulish';
  color: #838383;
  padding-top: 12px;
}

.subtitle-postlist{
  font-size: 19px;
  line-height: 35px;
  font-family: 'Mulish';
  color: #838383;
  margin-bottom: 16px;
  padding-top: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle-post{
  font-size: 19px;
  line-height: 35px;
  font-family: 'Lora';
  color: #6e6e6e;
}


.text-body{
  padding-top: 24px;
  font-family: 'Lora';
  font-weight: 300;
  flex-direction: column;
  align-items: center;
  font-size: 19px;
  line-height: 35px;
}

p{
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin: 0 0 3%;
}

.character{
  max-width: 100%;
}

.link-footer{
  font-size: 16px;
  color: #838383;
  line-height: 22px;
  text-decoration: auto;
  display: flex;
  padding-bottom: 0.5rem;
}

.logoFooter{
  padding-bottom: 0.6rem;
  max-height: 50px;
  padding-top: 0.3rem;
  margin-top: 0.6rem;
}

.social-icon{
  display: inline-block;
  margin: 0 20px 0 0;
  font-size: 16px;
  padding: 5px 0;
  line-height: 22px;
}

.social-icon img{
  display: block;
  width: 25px;
}

p img{
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.8rem;
}

pre {
  white-space: break-spaces;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: inherit;
}

.subtitle-postlist p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.social-share-post{
  display: flex !important;
}

.social-share-post p {
  font-size: 16px !important;
}